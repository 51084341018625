import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import SearchForm from './forms/SearchForm';
import { jsonToQueryString, queryStringToJson } from '../utils/queryString';
import { getSearchParams, getCommonParams, getDetailsParams } from '../utils/forms/search';
import { getRentByValue } from '../utils/estate/rent';
import * as estatesActions from '../actions/estatesActions';
import { fetchCityById } from '../actions/citiesActions';
import { getProvinceByValue } from '../utils/provinces';
import TabHeader from '../components/search/TabHeader';

const TabHeaderApartment = <TabHeader label="Mieszkania" icon="user" />;
const TabHeaderHouse = <TabHeader label="Domy" icon="home" />;
const TabHeaderLand = <TabHeader label="Działki" icon="tree-deciduous" />;
const TabHeaderPlace = <TabHeader label="Lokale" icon="shopping-cart" />;
const TabHeaderObject = <TabHeader label="Obiekty" icon="map-marker" />;

class Search extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { loaded: false, city: null };

    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleFormApartment = this.handleForm.bind(this, 'apartment');
    this.handleFormHouse = this.handleForm.bind(this, 'house');
    this.handleFormLand = this.handleForm.bind(this, 'land');
    this.handleFormPlace = this.handleForm.bind(this, 'place');
    this.handleFormObject = this.handleForm.bind(this, 'object');

    props.searchReset();
  }

  async componentDidMount() {
    const queryParams = queryStringToJson(this.props.location.search);
    if (!queryParams.city) return this.setState({ loaded: true });

    const city = await fetchCityById(queryParams.city).then(res => res.json()).catch(() => null);
    this.setState({ loaded: true, city });
  }

  handleForm(type, data) {
    const handleBool = (item) => (_.isBoolean(item) ? Number(item) : item);
    const getValue = (item) => (_.isObject(item) ? item.value : handleBool(item));
    const fields = {
      type, page: 1, ...(_.mapValues({ ...data.common, ...data.details }, getValue)),
    };
    const filteredFields = _.pick(fields, getSearchParams());
    const queryString = jsonToQueryString(filteredFields);

    this.props.updateSearchQuery(type, data);
    this.props.history.push(`/wyszukaj-ogloszenia?${queryString}`);
  }

  handleTabChange(type) {
    const queryParams = queryStringToJson(this.props.location.search);
    if (queryParams.type && queryParams.type !== type) {
      const queryString = jsonToQueryString({ type, page: 1 });
      this.props.history.push(`/wyszukaj-ogloszenia?${queryString}`);
    }
  }

  getInitialValues(type) {
    const data = this.populateSearchValues();

    return {
      common: _.pick(data, getCommonParams()),
      details: _.pick(data, getDetailsParams()[type]),
    };
  }

  populateSearchValues() {
    const queryObj = queryStringToJson(this.props.location.search);
    const queryParams = _.pickBy(queryObj, (val, key) => key === 'rent' || val !== '0');

    Object.keys(queryParams).forEach((key) => {
      switch (key) {
        case 'rent':
          queryParams[key] = getRentByValue(queryParams[key]); break;
        case 'province':
          queryParams[key] = getProvinceByValue(queryParams[key]); break;
        case 'city':
          const { city } = this.state;
          queryParams[key] = city ? { value: city.cityId, label: city.city } : null; break;
        default:
          break;
      }
    });

    return queryParams;
  }

  render() {
    const { type } = queryStringToJson(this.props.location.search);

    if (!this.state.loaded) return null;

    return (
      <div className="container">
        <div className="row">
          <div className="search-container">
            <div className="content search">
              <Tabs id="SearchTabs" className="nav-full" defaultActiveKey={type}
                animation={false} mountOnEnter unmountOnExit onSelect={this.handleTabChange}
              >
                <Tab eventKey="apartment" title={TabHeaderApartment}>
                  <SearchForm type="apartment" onSubmit={this.handleFormApartment}
                    initialValues={this.getInitialValues('apartment')}
                  />
                </Tab>
                <Tab eventKey="house" title={TabHeaderHouse}>
                  <SearchForm type="house" onSubmit={this.handleFormHouse}
                    initialValues={this.getInitialValues('house')}
                  />
                </Tab>
                <Tab eventKey="land" title={TabHeaderLand}>
                  <SearchForm type="land" onSubmit={this.handleFormLand}
                    initialValues={this.getInitialValues('land')}
                  />
                </Tab>
                <Tab eventKey="place" title={TabHeaderPlace}>
                  <SearchForm type="place" onSubmit={this.handleFormPlace}
                    initialValues={this.getInitialValues('place')}
                  />
                </Tab>
                <Tab eventKey="object" title={TabHeaderObject}>
                  <SearchForm type="object" onSubmit={this.handleFormObject}
                    initialValues={this.getInitialValues('object')}
                  />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Search.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  updateSearchQuery: PropTypes.func.isRequired,
  searchReset: PropTypes.func.isRequired,
};

export default withRouter(connect(
  () => ({}),
  (dispatch) => bindActionCreators(estatesActions, dispatch),
)(Search));
