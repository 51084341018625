import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as breadcrumbsActions from '../actions/breadcrumbsActions';
import * as siteActions from '../actions/siteActions';
import OfferList from '../components/OfferList';
import MetaTags from '../components/MetaTags';
import ContactForm from '../containers/forms/ContactForm';

const EMAIL_ADDRESS = 'info@tanieruchomosci.pl';

class Contact extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { showEmail: false };
    this.showEmail = this.showEmail.bind(this);
    this.handleForm = this.handleForm.bind(this);
  }

  componentDidMount() {
    this.props.breadcrumbs([{ title: 'Kontakt' }]);
  }

  handleForm(values) {
    this.props.sendContactForm(values);
  }

  showEmail() {
    this.setState({ showEmail: true });
  }

  render() {
    return (
      <div className="contact-page">
        <MetaTags title="Kontakt" />

        <div className="row">
          <h1>Kontakt</h1>
        </div>

        <p>
          Aby skontaktować się z portalem skorzystaj z danych kontaktowych zamieszczonych poniżej
          lub wyślij nam wiadomość za pomocą formularza kontaktowego.
          <br />
          Zazwyczaj odpowiadamy
          w&nbsp;ciągu 24 godzin.
          <br />
          <br />
        </p>

        <div className="ad-details">
          <ul className="details-list col-lg-6 col-md-6 col-sm-6">
            <li className="li-grey">
              Dane kontaktowe:
            </li>
            <li>
              <i className="fa fa-envelope" />
              {' '}
              E-mail:
              {' '}
              {this.state.showEmail
                ? (<span><strong>{EMAIL_ADDRESS}</strong></span>) : (
                  <button type="button" className="btn show-info-btn" onClick={this.showEmail}>
                    Pokaż adres e-mail
                  </button>
                )}
            </li>
            <li>
              <p>
                <strong>Uwaga!</strong>
                <br />
                Przed wysłaniem wiadomości sprawdź czy odpowiedź na Twoje pytanie nie znajduje się
                w&nbsp;
                <Link to="/faq">najczęściej zadawanych pytaniach</Link>
                .
              </p>
            </li>
          </ul>

          <ul className="details-list col-lg-6 col-md-6 col-sm-6">
            <li className="li-grey">Formularz kontaktowy:</li>
            <li>
              <ContactForm onSubmit={this.handleForm} />
            </li>
          </ul>
        </div>
        <div className="clearfix" />

        <OfferList />
      </div>
    );
  }
}

Contact.propTypes = {
  breadcrumbs: PropTypes.func.isRequired,
  sendContactForm: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  (dispatch) => bindActionCreators({ ...breadcrumbsActions, ...siteActions }, dispatch),
)(Contact);
