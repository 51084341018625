import React from 'react';
import PropTypes from 'prop-types';
import SafeButton from '../../containers/estate/SafeButton';

const serverAdUrl = encodeURIComponent(window.location.href);

function ShareEstate({ estate }) {
  return (
    <div className="share-wraper">
      <ul className="social-networks">
        <li className="pull-right">
          <a className="print-button" title="Wydrukuj" role="button" tabIndex={0}
            onClick={() => (window.print())} onKeyDown={() => (window.print())}
          >
            <i className="fa fa-print" />
          </a>
        </li>
        <li className="pull-right">
          <a
            href={`mailto:?subject=${encodeURIComponent(estate.title)}&body=${serverAdUrl}`}
            title="Wyślij na e-mail"
          >
            <i className="fa fa-envelope" />
          </a>
        </li>
        <li className="pull-right">
          <SafeButton estateId={estate.estateId} />
        </li>
      </ul>
    </div>
  );
}

ShareEstate.propTypes = {
  estate: PropTypes.object.isRequired,
};

export default ShareEstate;
