import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Alert, Button, ButtonGroup, Glyphicon, DropdownButton, MenuItem } from 'react-bootstrap';
import * as breadcrumbsActions from '../../actions/breadcrumbsActions';
import * as estatesActions from '../../actions/estatesActions';
import EstateCard from '../../components/EstateCard';
import MetaTags from '../../components/MetaTags';
import { pageUrl } from '../../utils/url';
import Paginator from '../../components/Paginator';
import countPages from '../../utils/estate/countPages';
import { sortCacheKey, sortOptions } from '../../utils/estate/sort';

class UserEstatesList extends PureComponent {
  constructor(props) {
    super(props);

    const storedSort = sessionStorage.getItem(sortCacheKey);
    this.state = { grid: true, sort: storedSort ? JSON.parse(storedSort) : sortOptions[0] };

    this.gridViewOn = this.activateGridView.bind(this, true);
    this.gridViewOff = this.activateGridView.bind(this, false);
  }

  componentDidMount() {
    this.fetchData();

    const { userId } = this.props.match.params;
    this.props.breadcrumbs([{ title: `Ogłoszenia użytkownika #${userId}` }]);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match !== this.props.match || prevState.sort !== this.state.sort) {
      this.fetchData();
    }
  }

  activateGridView = (grid) => {
    this.setState({ grid });
  };

  handleSelect = (eventKey) => {
    const selectedOption = sortOptions.find(option => option.key === eventKey);
    if (selectedOption) {
      this.setState({ sort: selectedOption });
      sessionStorage.setItem(sortCacheKey, JSON.stringify(selectedOption));
    }
  };

  fetchData() {
    const { userId, page } = this.props.match.params;
    const { sort } = this.state;
    const query = `page=${page || 1}&sortBy=${sort.sortBy}&sortOrder=${sort.sortOrder}`;

    this.props.getEstatesByUserId(userId, query);
  }

  static renderSortDropdown() {
    if (!sortOptions || sortOptions.length < 1) {
      return null;
    }

    return _.map(sortOptions, (option) => (
      <MenuItem key={option.key} eventKey={option.key}>
        {option.label}
      </MenuItem>
    ));
  }

  renderList() {
    const { estatesList } = this.props;

    const estates = (estatesList ? estatesList.rows : Array(8).fill({}))
      .map((estate) => (
        <li key={estate.estateId ? estate.estateId : Math.random()} className="col-xs-12 col-sm-6 col-md-3">
          <EstateCard {...estate} />
        </li>
      ));

    return (
      <ul>{estates}</ul>
    );
  }

  render() {
    const page = parseInt(this.props.match.params.page, 10) || 1;
    const { userId } = this.props.match.params;
    const { estatesList } = this.props;
    const { grid, sort } = this.state;
    const title = `Ogłoszenia użytkownika #${userId}:`;

    return (
      <div className="estates-page">
        <MetaTags title={title} />

        <div id="ads" className={`row estates-short-list${!grid ? ' estates-list' : ''}`}>
          <h1>{title}</h1>

          {(!_.isEmpty(estatesList) && estatesList.rows.length === 0) && (
            <div className="col-lg-12">
              <Alert bsStyle="info" className="text-center">
                W chwili obecnej kategoria nie posiada dodanych ofert!
              </Alert>
            </div>
          )}

          {(!_.isEmpty(estatesList) && estatesList.rows.length > 0) && (
            <div className="estates-list-sort">
              <span>Sortuj: </span>
              <DropdownButton title={sort.label} onSelect={this.handleSelect}>
                {UserEstatesList.renderSortDropdown()}
              </DropdownButton>
            </div>
          )}

          {(!_.isEmpty(estatesList) && estatesList.rows.length !== 0) && (
            <ButtonGroup className="estates-list-types">
              <Button className={`estates-list-type ${grid ? ' active' : ''}`} title="Siatka"
                onClick={this.gridViewOn}
              >
                <Glyphicon glyph="th" />
              </Button>
              <Button className={`estates-list-type ${grid ? '' : 'active'}`} title="Lista"
                onClick={this.gridViewOff}
              >
                <Glyphicon glyph="th-list" />
              </Button>
            </ButtonGroup>
          )}

          {this.renderList()}
        </div>

        {(!_.isEmpty(estatesList) && estatesList.rows.length > 0) && (
          <Paginator page={page} pages={countPages(estatesList)}
            url={pageUrl(this.props.match.url, page)}
          />
        )}
      </div>
    );
  }
}

UserEstatesList.propTypes = {
  breadcrumbs: PropTypes.func.isRequired,
  match: PropTypes.object,
  estatesList: PropTypes.shape({
    count: PropTypes.number,
    rows: PropTypes.array,
  }),
  getEstatesByUserId: PropTypes.func.isRequired,
};

export default withRouter(connect(
  (state) => ({ estatesList: state.estates.userEstatesList }),
  (dispatch) => bindActionCreators({ ...breadcrumbsActions, ...estatesActions }, dispatch),
)(UserEstatesList));
