import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Alert, Button, ButtonGroup, Glyphicon, DropdownButton, MenuItem } from 'react-bootstrap';
import * as breadcrumbsActions from '../../actions/breadcrumbsActions';
import * as estatesActions from '../../actions/estatesActions';
import EstateCard from '../../components/EstateCard';
import { jsonToQueryString, queryStringToJson } from '../../utils/queryString';
import { getSearchParams } from '../../utils/forms/search';
import { sortCacheKey, sortOptions } from '../../utils/estate/sort';
import MetaTags from '../../components/MetaTags';
import { pageUrl } from '../../utils/url';
import Paginator from '../../components/Paginator';
import countPages from '../../utils/estate/countPages';

class SearchResult extends PureComponent {
  static title = 'Wyszukiwarka';

  constructor(props) {
    super(props);

    const storedSort = sessionStorage.getItem(sortCacheKey);
    this.state = { grid: true, sort: storedSort ? JSON.parse(storedSort) : sortOptions[0] };

    this.gridViewOn = this.activateGridView.bind(this, true);
    this.gridViewOff = this.activateGridView.bind(this, false);
  }

  componentDidMount() {
    this.fetchData();

    this.props.breadcrumbs([{ title: SearchResult.title }]);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match !== this.props.match || prevState.sort !== this.state.sort) {
      this.fetchData();
    }
  }

  activateGridView = (grid) => {
    this.setState({ grid });
  };

  handleSelect = (eventKey) => {
    const selectedOption = sortOptions.find(option => option.key === eventKey);
    if (selectedOption) {
      this.setState({ sort: selectedOption });
      sessionStorage.setItem(sortCacheKey, JSON.stringify(selectedOption));
    }
  };

  fetchData() {
    const params = queryStringToJson(this.props.location.search);
    const { sort } = this.state;
    const filteredParams = _.pick(params, getSearchParams());
    const queryParams = _.pickBy(filteredParams, (val, key) => key === 'rent' || val !== '0');
    queryParams.sortBy = sort.sortBy;
    queryParams.sortOrder = sort.sortOrder;
    const queryString = jsonToQueryString(queryParams);

    this.props.getSearchResult(queryString);
  }

  static renderSortDropdown() {
    if (!sortOptions || sortOptions.length < 1) {
      return null;
    }

    return _.map(sortOptions, (option) => (
      <MenuItem key={option.key} eventKey={option.key}>
        {option.label}
      </MenuItem>
    ));
  }

  renderList() {
    const { estatesList } = this.props;

    const estates = (estatesList ? estatesList.rows : Array(8).fill({}))
      .map((estate) => (
        <li key={estate.estateId ? estate.estateId : Math.random()} className="col-xs-12 col-sm-6 col-md-3">
          <EstateCard {...estate} />
        </li>
      ));

    return (
      <ul>{estates}</ul>
    );
  }

  render() {
    const { pathname, search } = this.props.location;
    const params = queryStringToJson(search);
    const page = parseInt(params.page, 10) || 1;
    const { estatesList } = this.props;
    const { grid, sort } = this.state;

    return (
      <div className="estates-page">
        <MetaTags title={SearchResult.title} />

        <div id="ads" className={`row estates-short-list${!grid ? ' estates-list' : ''}`}>
          <h1>Wyniki wyszukiwania:</h1>

          {(!_.isEmpty(estatesList) && estatesList.rows.length === 0) && (
            <div className="col-lg-12">
              <Alert bsStyle="info" className="text-center">
                W chwili obecnej brak wyników dla podanych kryteriów wyszukiwania!
              </Alert>
            </div>
          )}

          {(!_.isEmpty(estatesList) && estatesList.rows.length > 0) && (
            <div className="estates-list-sort">
              <span>Sortuj: </span>
              <DropdownButton title={sort.label} onSelect={this.handleSelect}>
                {SearchResult.renderSortDropdown()}
              </DropdownButton>
            </div>
          )}

          {(!_.isEmpty(estatesList) && estatesList.rows.length !== 0) && (
            <ButtonGroup className="estates-list-types">
              <Button className={`estates-list-type ${grid ? ' active' : ''}`} title="Siatka"
                onClick={this.gridViewOn}
              >
                <Glyphicon glyph="th" />
              </Button>
              <Button className={`estates-list-type ${grid ? '' : 'active'}`} title="Lista"
                onClick={this.gridViewOff}
              >
                <Glyphicon glyph="th-list" />
              </Button>
            </ButtonGroup>
          )}

          {this.renderList()}
        </div>

        {(!_.isEmpty(estatesList) && estatesList.rows.length > 0) && (
          <Paginator page={page} pages={countPages(estatesList)}
            url={pageUrl(`${pathname}${search}`, page)}
          />
        )}
      </div>
    );
  }
}

SearchResult.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  estatesList: PropTypes.shape({
    count: PropTypes.number,
    rows: PropTypes.array,
  }),
  getSearchResult: PropTypes.func.isRequired,
  breadcrumbs: PropTypes.func.isRequired,
};

export default withRouter(connect(
  (state) => ({ estatesList: state.estates.searchResult }),
  (dispatch) => bindActionCreators({ ...breadcrumbsActions, ...estatesActions }, dispatch),
)(SearchResult));
