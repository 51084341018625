import React from 'react';

const year = new Date().getFullYear();

function Footer() {
  return (
    <footer>
      <div className="container">
        Copyright © 2010 -
        {' '}
        {year}
        {' '}
        taNieruchomości.pl
        <ul className="social-networks">
          <li>
            <a
              href="https://www.facebook.com/tanieNIERUCH0M0SCI" target="_blank"
              rel="noopener noreferrer"
              title="Polub nas na Facebook.com"
            >
              <i className="fa fa-thumbs-o-up" />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
